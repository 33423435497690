<template>
  <div class="body-wide noselect" :class="{ narrow: wParam }">
    <div v-if="isSmallScreen" class="page-timetable__header">
      <div class="page-timetable__title">Расписание</div>
      <div class="page-timetable__filter-data">
        <div>
          <div class="page-timetable__point">{{ pointName }}</div>
          <div class="page-timetable__date-range">{{ formatedDateRange }}</div>
        </div>
        <base-icon path="Filter.svg" width="32" height="32" @click="showFilter = !showFilter" />
      </div>
    </div>
    <div v-if="showFilter || !isSmallScreen" class="page-timetable__filter heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block">
        <PointSelector label="Точка" :point-id="id_point" @change="handlePoint" />
      </div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetrieve(null)" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
      <div v-if="showChecks" class="page-timetable__checkbox flexR center">
        <input type="checkbox" v-model="salesShow" />
        <span class="page-timetable__label center">Чеки&nbsp;{{ sales.length }}{{ sales[0] }}</span>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <!-- <transition name="fade"> -->
    <div v-if="!loading">
      <!-- <HoursRuler /> -->
      <ChessItself
        v-show="!showFilter"
        :autoTable="autoTable"
        :tbl="tbl"
        :TURNS="TURNS"
        :id_point="id_point"
        :people="people"
        :jobs="jobs"
        :sales="sales"
        :salesShow="salesShow"
        :date_begin="date_begin"
        :id="'chesstableID'"
        :type="'real'"
      />
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { TURNS_ } from "@/components-js/turns";
import { datesCheck } from "@/components-js/datesCheck.js";
import { lS } from "@/components-js/localStorage";
import { HOUR_START, HOUR_END, DATE_MAX_INTERVAL } from "@/config/settings";
import { isMobile } from "@/lib/helpers/checkDevice";
import BaseIcon from "@/components/BaseIcon";
import DateSelector from "@/components/DateSelector.vue";
import PointSelector from "@/components/PointSelector.vue";
// import HoursRuler from "@/components/HoursRuler.vue";
import ChessItself from "@/components/ChessItself.vue";
import Loader from "@/components/Loader.vue";
const loStorage = new lS();

export default {
  components: {
    BaseIcon,
    DateSelector,
    PointSelector,
    // HoursRuler,
    Loader,
    ChessItself,
  },

  props: {
    wParam: String,
  },

  data() {
    return {
      autoTable: false,
      loading: true,
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,
      HOUR_START,
      HOUR_END,
      tbl: [],
      id_point: 0,
      pointName: "",
      showFilter: false,

      people: [],
      jobs: [],
      TURNS: null,
      sales: {},
      salesLength: 0,
      salesShow: false,
      isLaptop: false,
    };
  },

  computed: {
    formatedDateRange() {
      return `${dateFormatVarious(this.date_begin)} - ${dateFormatVarious(this.date_end)}`;
    },
    isSmallScreen() {
      return this.isMobile() || this.isLaptop;
    },
    showChecks() {
      return this.$root?.user?.roles?.includes("admin") && this.salesLength && !this.salesShow;
    },
  },

  async created() {
    //console.log(this.$root.user);
    this.TURNS = new TURNS_(this.$root?.user?.uid);
  },

  async mounted() {
    this.handleResizeSlider();
    // get list of people
    this.people = await request("/api/user/listall", "GET");
    // get list of jobs
    this.jobs = await request("/api/job/list", "GET");

    // set default values for dates and point
    const d = new Date();
    this.date_begin = this.date_end = dateFormatJS(d);

    if (this.$route.params && this.$route.params.date) {
      this.date_begin = this.$route.params.date;
    } else {
      let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
      if (tmp) this.date_begin = tmp;
    }

    if (this.$route.params && this.$route.params.date) {
      this.date_end = this.$route.params.date;
    } else {
      let tmp = loStorage.getObjectProp(this.$route.path, "date_end");
      if (tmp) this.date_end = tmp;
    }

    if (this.$route.params && this.$route.params.id_point) {
      this.id_point = this.$route.params.id_point;
    } else {
      let tmp = loStorage.getObjectProp(this.$route.path, "id_point");
      if (tmp) this.id_point = tmp;
    }

    this.tableDataRetrieve(this.$route.params && this.$route.params.id_point);
  },

  beforeDestroy() {
    this.TURNS.clear();
  },

  methods: {
    handleResizeSlider() {
      if (window?.innerWidth < 1300 && !this.isLaptop) {
        this.isLaptop = true;
      }

      if (window?.innerWidth > 1300) {
        this.isLaptop = false;
      }
    },
    handlePoint(point) {
      this.id_point = point.id;
      this.pointName = point.nickname;
    },
    isMobile,
    // getting data from server
    async tableDataRetrieve(routeJumpFlag) {
      const hourCondition =
        this.$root?.user && (this.$root?.user?.business === "REBELS" || this.$root?.user?.business === "REBELS ++");
      if (hourCondition) {
        this.HOUR_START = 22;
        this.HOUR_END = 30;
      }

      this.showFilter = false;
      this.loading = true;
      this.salesShow = false;

      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      // DB request
      let q = "/api/turn/list";
      if (this.autoTable) q = "/api/turn/listauto";

      this.salesLength = 0;
      const salesTemp = await request("/api/sales/salesget", "POST", {
        id_point: this.id_point,
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
      this.sales = {};
      for (const el of salesTemp) {
        if (this.sales[el.date] == undefined) this.sales[el.date] = [];
        this.sales[el.date][el.hour] = el.sales;
        this.salesLength++;
      }
      salesTemp.length = 0;

      const data = await request(
        q,
        "POST",
        {
          id_point: this.id_point,
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        routeJumpFlag ? null : this.$route.path
      );

      // even if data array is empty, still need to show empty table

      // temporary array
      let tbl_copy = [];

      // from date_begin to date_end
      let d1 = new Date(this.date_begin);
      const d2 = new Date(this.date_end);

      let orderAbs = 0;
      let orderMax = 0;
      while (d1 <= d2) {
        // row order in a turn
        let turn_order = 0;
        let date_current = dateFormatJS(d1);
        // if there is a TURN at the date
        if (data.findIndex(item => item.turn_date === date_current) > -1) {
          // found TURN at the date
          // remember row, where to start fill array
          let length_current = tbl_copy.length;
          orderMax = 0;
          // through all TURN array, for every row in dates array
          for (let X of data.filter(item => item.turn_date === date_current)) {
            // get order in a turn from turn record
            turn_order = X.turn_order;

            // create new row in tbl, if not exist
            if (tbl_copy[length_current + turn_order] === undefined) {
              // fix max order
              orderMax = turn_order;
              // if we have empty row(s)
              for (let l = length_current; l <= length_current + turn_order; l++) {
                if (tbl_copy[l] === undefined) {
                  tbl_copy[l] = [];
                  for (let i = this.HOUR_START; i <= this.HOUR_END; i++) tbl_copy[l][i] = 0;
                  // prelast column is date
                  tbl_copy[l].push(date_current);
                  // last column is turn_order
                  tbl_copy[l].push(l - length_current);
                }
              }
            }

            // through hours
            for (let k = X.hour_begin; k <= X.hour_end; k++)
              // insert turn
              tbl_copy[length_current + turn_order][k] = X.id_turn;

            X.orderAbs = orderAbs + turn_order;
          }
          orderAbs += orderMax;
          orderAbs++;
        } else {
          // TURN is absent at the date, fill it with zero values
          let date_current = dateFormatJS(d1);
          // add row into tbl array
          tbl_copy[tbl_copy.length] = [];
          // increase order
          orderAbs++;
          // fill row of tbl array with zero values
          for (let k = this.HOUR_START; k <= this.HOUR_END; k++) tbl_copy[tbl_copy.length - 1][k] = 0;
          // prerlast column is date
          tbl_copy[tbl_copy.length - 1].push(date_current);
          // last column is turn_order
          tbl_copy[tbl_copy.length - 1].push(turn_order);
        }

        d1 = new Date(d1.setDate(d1.getDate() + 1));
      }
      this.TURNS.fill(data);
      this.tbl = tbl_copy;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-old {
  .page-timetable {
    &__header {
      padding: 24px 16px 16px;
    }

    &__filter {
      @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
      }
    }

    &__filter-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 20px;
      font-weight: 800;
      line-height: 28px;
    }

    &__point {
      margin-top: 16px;
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
    }

    &__date-range {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    &__checkbox {
      padding: 0.5rem;
    }

    &__label {
      margin-left: 0.5rem;
    }

    @media (max-width: 1300px) {
      &__checkbox {
        padding-bottom: 12px;
        margin-left: 12px;
        font-size: 14px;
        font-weight: 400;

        input[type="checkbox"] {
          width: unset;
          height: unset;
        }
      }

      &__filter {
        flex-direction: column;
      }
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.narrow {
  width: 100%;
}
</style>
